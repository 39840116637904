import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useIdleTimer } from 'react-idle-timer';
import { SERVICE_URL } from 'services/ApiService';
import { withRouter } from 'react-router-dom';

const minutesTillLogout = () => {
  switch (SERVICE_URL) {
    case 'http://localhost:3000':
      return 10000;
    case 'https://staging.go4.io/api':
      return 10;
    default:
      return 10;
  }
};

const AutoLogout = (props) => {
  const [open, setOpen] = useState(false);
  const [expireTime, setExpireTime] = useState(10); // 10 seconds
  const [isIdle, setIdle] = useState(false);
  const logout = () => props.history.push('/logout');
  const idleTimeout = minutesTillLogout() * 60 * 1000; // 10 minutes

  const handleIdle = () => {
    setIdle(true);
    logout();
  };

  const startExpireCountdown = () => {
    const interval = setInterval(() => {
      setExpireTime((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setExpireTime(10);
    }, expireTime * 1000);
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: expireTime * 1000,
    onPrompt: () => {
      setOpen(true);
      setExpireTime(10);
      startExpireCountdown();
    },
    onIdle: handleIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (isIdle) {
      idleTimer.pause();
    }
  }, [isIdle, idleTimer]);

  const stay = () => {
    setOpen(false);
    setIdle(false);
    idleTimer.reset();
    setExpireTime(10);
  };

  return (
    <div className="v2-confirmation-modal" style={{ position: 'absolute' }}>
      <Modal isOpen={open} toggle={() => setOpen(!open)} className="v2-confirmation-modal-content">
        <ModalHeader className="header">
          Your session will expire in {expireTime} seconds
        </ModalHeader>
        <ModalBody>
          <p>You will be automatically logged out for security reasons.</p>
        </ModalBody>
        <ModalFooter className="actions">
          <button className="btn confirm-btn" onClick={logout}>
            Log out
          </button>
          <button className="btn btn-success" onClick={stay}>
            Keep working
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withRouter(AutoLogout);
