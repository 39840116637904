import { get } from 'lodash';

export const getPaymentType = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return 'bank_account';

  return get(payment, 'source.object', null);
};

export const hasPaymentToPublish = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return true;

  return !!payment && !!payment.source && !!payment.source.id && !!payment.loadedSource;
};

export const hasPaymentToUpdate = (payment) => {
  if (hasToVerifyMicroDeposits(payment)) return false;

  return !!payment && !!payment.source && !!payment.source.id && !!payment.loadedSource;
};

export const hasToVerifyMicroDeposits = (payment) => {
  return !!Object.keys(payment).includes('publicToken') && !!payment.publicToken;
};

export const getErrorMessage = (message) => {
  return message.replace(/\(.*?\)/g, '').trim();
};
