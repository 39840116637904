import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import zipcodes from 'zipcodes';
import {
  EXPECTED_ATS_OPTIONS,
  HEAR_ABOUT_US_ADDITIONAL_OPTIONS,
  HEAR_ABOUT_US_OPTIONS,
  checkAccountExists,
  postEventAccountFieldValidate,
  postEventFieldValidateReducer,
  postEventProfileFieldValidate,
  registrationFieldUpdate,
  registrationPostEvent,
  setRole,
} from 'redux/ducks/Registration';
import { optionsForEnum, ORGANIZATION_TYPES } from 'helpers/enums';
import { sortBy, first } from 'lodash';
import { changeProfileField } from 'redux/ducks/Post/Profile';

const validateFieldsNames = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'password',
  'passwordConfirmation',
  'hearAboutUsSource',
  'hearAboutUsDetails',
];

const profileValidateFieldsNames = [
  'defaultEventSettingId',
  'expectedAnnualHires',
  'companyName',
  'addressZip',
];

const SignUpModal = ({
  history,
  updateField,
  user,
  validateField,
  validation,
  checkAccountExists,
  setRole,
  isOpen,
  loginModal,
  toggleSignUpModal,
  toggleLogInModal,
  location,
  professions,
  eventSettings,
  states,
  registrationPostEvent,
  validateProfileField,
  profileValidation,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    setRole('EventOperator');
    updateField('postEventMasterType', '');
  }, [setRole, updateField]);

  if (props.authenticated) return null;

  const onFieldValidate = (field) => {
    validateField(field);
  };

  const onProfileFieldValidate = (field) => {
    validateProfileField(field);
  };

  const onCreateAccount = (path) => {
    if (!path.includes('/profile')) return;

    window.grecaptcha.ready(function () {
      console.log('grecaptcha ready');
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3, { action: 'signup_eo' })
        .then(function (token) {
          updateField('token', token);
          registrationPostEvent({ push: toggleSignUpModal }, '/v2/signup');
          props.changeProfileField('isFirstEvent', true);
        });
    });
  };

  let timeoutId;
  const scrollToId = (id) => {
    // Clear any existing timeout before setting a new one
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const scrollToError = () => {
    const key = first(Object.keys(validation.errors).concat(Object.keys(profileValidation.errors)));

    if (!!key) scrollToId(key);
  };

  const onNext = (e) => {
    e.preventDefault();
    validateFieldsNames.forEach((field) => {
      onFieldValidate(field);
      postEventFieldValidateReducer(validation, props.registrationState, field);
    });
    profileValidateFieldsNames.forEach((field) => {
      onProfileFieldValidate(field);
      postEventFieldValidateReducer(profileValidation, props.registrationState, field);
    });

    scrollToError();

    if (!validation.valid || !profileValidation.valid) return;

    checkAccountExists({ history: { push: onCreateAccount }, keepSpinner: true });
  };

  const onFieldChange = (field, triggerValidate) => {
    return (ev: SyntheticInputEvent<HTMLInputElement>) => {
      updateField(field, ev.currentTarget.value);
      if (triggerValidate) {
        onFieldValidate(field);
      }
    };
  };

  const onProfileFieldChange = (field, triggerValidate, transformer) => {
    return (ev: SyntheticInputEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = ev;

      updateField(field, transformer ? transformer(value) : value);
      if (triggerValidate) {
        onProfileFieldValidate(field);
      }
    };
  };

  const onPhoneNumberChange = (phone) => {
    const phoneNo = phone.length === 1 && phone.at(0) !== '1' ? `1${phone}` : phone;

    updateField('phone', phoneNo);
    onFieldValidate('phone');
  };

  const onTermsChanged = (e) => {
    updateField('terms', e.target.checked);
    validateField('terms');
  };

  const ADDITIONAL_HEAR_OPTIONS = HEAR_ABOUT_US_ADDITIONAL_OPTIONS.filter((op) => op !== 'Other');

  const onZipCodeChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    const location = zipcodes.lookup(ev.currentTarget.value);
    updateField('addressZip', ev.currentTarget.value);
    onProfileFieldValidate('addressZip');
    if (location?.city) {
      updateField('city', location.city);
      onProfileFieldValidate('city');
    }
    if (location?.state) {
      updateField('state', location.state);
      onProfileFieldValidate('state');
    }
  };

  const HEAR_ABOUT_US_OPTIONS_SORTED = sortBy(
    HEAR_ABOUT_US_OPTIONS.filter((op) => op !== 'Other')
  ).concat('Other');

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleSignUpModal}
      className="signUpModal"
      style={{ minWidth: '50vw' }}
      backdrop="static"
    >
      <ModalHeader toggle={toggleSignUpModal} className="gotham-bold-font">
        Create Your Account
      </ModalHeader>
      <ModalBody className={'px-4 bg-white py-0'}>
        <HorizontalRule margin={0} />
        <main>
          <div className="content">
            <div className="info-text nimbus-regular-font">
              To continue posting your first job, create an account.
            </div>

            <form
              id="accountForm"
              className="gotham-regular-font"
              noValidate
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      type="text"
                      placeholder="John"
                      value={user.firstName}
                      className={classnames('form-control italic-placeholder nimbus-regular-font', {
                        'is-invalid': validation.errors.firstName,
                      })}
                      name="firstName"
                      id="firstName"
                      onChange={onFieldChange('firstName')}
                      onBlur={onFieldValidate.bind(this, 'firstName')}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {validation.errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Snow"
                      value={user.lastName}
                      className={classnames('form-control italic-placeholder nimbus-regular-font', {
                        'is-invalid': validation.errors.lastName,
                      })}
                      name="lastName"
                      id="lastName"
                      onChange={onFieldChange('lastName')}
                      onBlur={onFieldValidate.bind(this, 'lastName')}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {validation.errors.lastName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                    <label className="required" htmlFor="companyName">
                      Organization Name
                    </label>
                    <input
                      type="text"
                      placeholder="Bayside High School"
                      value={user.companyName}
                      className={classnames('form-control italic-placeholder nimbus-regular-font', {
                        'is-invalid': profileValidation.errors.companyName,
                      })}
                      name="companyName"
                      id="companyName"
                      onChange={onProfileFieldChange('companyName')}
                      onBlur={onProfileFieldValidate.bind(this, 'companyName')}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {profileValidation.errors.companyName}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="required" htmlFor="addressZip">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      placeholder="90210"
                      value={user.addressZip}
                      className={classnames('form-control italic-placeholder nimbus-regular-font', {
                        'is-invalid': profileValidation.errors.addressZip,
                      })}
                      name="addressZip"
                      id="addressZip"
                      onChange={onZipCodeChange}
                      onBlur={onFieldValidate.bind(this, 'addressZip')}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {profileValidation.errors.addressZip}
                    </div>
                  </div>
                </div>
              </div>

              {/* line */}
              <div className="row">
                <div className="col-md-7">
                  {' '}
                  <div className="form-group">
                    <label className="required" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      value={user.email}
                      className={classnames('form-control italic-placeholder nimbus-regular-font', {
                        'is-invalid': validation.errors.email,
                      })}
                      name="email"
                      id="email"
                      onChange={onFieldChange('email')}
                      onBlur={onFieldValidate.bind(this, 'email')}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {validation.errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="required" htmlFor="phone" id="phone">
                      Phone
                    </label>
                    <PhoneInput
                      name="phone"
                      onlyCountries={['us']}
                      preferredCountries={['us']}
                      enableAreaCodes={false}
                      autoFormat={true}
                      isValid={!validation.errors.phone}
                      inputStyle={{ width: '100%' }}
                      placeholder="Enter phone number"
                      country="us"
                      onBlur={onFieldValidate.bind(this, 'phone')}
                      onChange={(newNumber) => onPhoneNumberChange(newNumber)}
                      value={user.phone}
                    />
                    <input
                      type="hidden"
                      className={classnames('form-control', {
                        'is-invalid': validation.errors.phone,
                      })}
                    />
                    <div className="invalid-feedback nimbus-regular-font">
                      {validation.errors.phone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <div className="form-group">
                    <label className="required" htmlFor="password">
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={classnames(
                          'form-control italic-placeholder nimbus-regular-font',
                          {
                            'is-invalid': validation.errors.password,
                          }
                        )}
                        placeholder="Password"
                        value={user.password}
                        name="password"
                        id="password"
                        onChange={(e) => {
                          onFieldChange('password')(e);
                          onFieldChange('passwordConfirmation', true)(e);
                        }}
                        onBlur={(e) => {
                          onFieldValidate.bind(this, 'password')(e);
                          onFieldValidate.bind(this, 'passwordConfirmation')(e);
                        }}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text rbr"
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={classnames('fas', {
                              'fa-eye': !showPassword,
                              'fa-eye-slash': showPassword,
                            })}
                          ></i>
                        </span>
                      </div>
                      <div className="invalid-feedback nimbus-regular-font">
                        {validation.errors.password}
                      </div>
                    </div>
                    <span className="password-disclaimer text-muted nimbus-regular-font">
                      Your password needs to be at least 8 characters, and include one uppercase and
                      one lowercase letter plus a special character.
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="form-group">
                    <label className="required" htmlFor="organization">
                      Select your organization type
                    </label>
                    <select
                      name="organization"
                      id="defaultEventSettingId"
                      className={classnames(
                        'form-control rounded-select nimbus-regular-font custom-select',
                        {
                          'is-invalid': profileValidation.errors.defaultEventSettingId,
                          'empty':
                            user.defaultEventSettingId === undefined ||
                            user.defaultEventSettingId === '',
                        }
                      )}
                      value={user.defaultEventSettingId !== null ? user.defaultEventSettingId : ''}
                      onChange={onProfileFieldChange('defaultEventSettingId', true)}
                      onBlur={onProfileFieldValidate.bind(this, 'defaultEventSettingId')}
                    >
                      {optionsForEnum(ORGANIZATION_TYPES)}
                    </select>
                    <div className="invalid-feedback nimbus-regular-font">
                      {profileValidation.errors.defaultEventSettingId}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="form-group">
                    <label className="required" htmlFor="hear_about_us">
                      How did you hear about us?
                    </label>
                    <select
                      name="hear_about_us"
                      id="hearAboutUsSource"
                      className={classnames(
                        'form-control rounded-select nimbus-regular-font custom-select',
                        {
                          'is-invalid':
                            validation.errors.hearAboutUsSource &&
                            !ADDITIONAL_HEAR_OPTIONS.includes(user.hearAboutUsSource),
                          'empty': !user.hearAboutUsSource,
                        }
                      )}
                      value={user.hearAboutUsSource}
                      onChange={onFieldChange('hearAboutUsSource', true)}
                      onBlur={onFieldValidate.bind(this, 'hearAboutUsSource')}
                    >
                      <option disabled value=""></option>
                      {HEAR_ABOUT_US_OPTIONS_SORTED.map((option, index) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {!ADDITIONAL_HEAR_OPTIONS.includes(user.hearAboutUsSource) && (
                      <div className="invalid-feedback nimbus-regular-font">
                        {validation.errors.hearAboutUsSource}
                      </div>
                    )}
                  </div>

                  {user.hearAboutUsSource === 'Other' && (
                    <div className="form-group">
                      <input
                        type="text"
                        value={user.hearAboutUsDetails}
                        className={classnames('form-control', {
                          'is-invalid': validation.errors.hearAboutUsDetails,
                        })}
                        name="hearAboutUsDetails"
                        id="hearAboutUsDetails"
                        onChange={onFieldChange('hearAboutUsDetails', true)}
                        onBlur={onFieldValidate.bind(this, 'hearAboutUsDetails')}
                      />
                    </div>
                  )}

                  {ADDITIONAL_HEAR_OPTIONS.includes(user.hearAboutUsSource) && (
                    <div className="form-group">
                      <label className="required" htmlFor="hearAboutUsDetails">
                        {user.hearAboutUsSource === 'Conference'
                          ? 'Event Name'
                          : 'Who recommended you?'}
                      </label>
                      <input
                        type="text"
                        value={user.hearAboutUsDetails}
                        className={classnames('form-control nimbus-regular-font', {
                          'is-invalid': validation.errors.hearAboutUsSource,
                        })}
                        name="hearAboutUsDetails"
                        id="hearAboutUsDetails"
                        onChange={onFieldChange('hearAboutUsDetails', true)}
                        onBlur={onFieldValidate.bind(this, 'hearAboutUsDetails')}
                      />
                      <div className="invalid-feedback nimbus-regular-font">
                        {validation.errors.hearAboutUsSource}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="form-group">
                    <label className="required" htmlFor="expectedAnnualHires">
                      How many per diem athletic trainers do you hire per year?
                    </label>
                    <select
                      name="expectedAnnualHires"
                      id="expectedAnnualHires"
                      className={classnames(
                        'form-control rounded-select nimbus-regular-font',
                        'custom-select',
                        {
                          'is-invalid': profileValidation.errors.expectedAnnualHires,
                          'empty': !user.expectedAnnualHires,
                        }
                      )}
                      value={user.expectedAnnualHires || ''}
                      onChange={onProfileFieldChange('expectedAnnualHires', true)}
                      onBlur={onProfileFieldValidate.bind(this, 'expectedAnnualHires')}
                    >
                      <option value="" disabled></option>
                      {EXPECTED_ATS_OPTIONS.map((option, index) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback nimbus-regular-font">
                      {profileValidation.errors.expectedAnnualHires}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group required checkbox privacy-policy">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="terms"
                    checked={!!user.terms}
                    onChange={onTermsChanged}
                  />
                  <label className="custom-control-label gotham-regular-font" htmlFor="terms">
                    I have read and agree to the{'\n'}{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://go4.io/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                    {', '}{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://go4.io/terms-and-conditions/"
                    >
                      Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href=" https://stripe.com/legal/connect-account"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Connected Account Agreement.
                    </a>
                  </label>
                </div>
              </div>
            </form>
          </div>
          {props.flash.message && props.flash.type === 'error' && (
            <div className="alert alert-danger" role="alert">
              {props.flash.message}
            </div>
          )}
        </main>
      </ModalBody>
      <ModalFooter className="actionBar px-4 py-1 bg-white">
        <HorizontalRule />
        <div className="actionBar__left pt-1"></div>
        <div className="actionBar__right pt-1">
          <Button
            onClick={onNext}
            className={classnames('actionBar__continue save_and_continue gotham-bold-font', {
              disabled: !validation.valid || !profileValidation.valid,
            })}
            block
            color="success"
          >
            <div>SAVE & CONTINUE</div>
          </Button>
          <button className="login-link login-mobile" onClick={toggleLogInModal}>
            HAVE AN ACCOUNT? LOG IN
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.registration.user,
    validation: state.registration.postEventAccountValidation,
    profileValidation: state.registration.postEventProfileValidation,
    states: state.enums.usStates,
    professions: state.enums.professions,
    eventSettings: state.enums.eventSettings,
    flash: state.flash,
    registrationState: state.registration,
    authenticated: state.session.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateField: (field, value) => dispatch(registrationFieldUpdate(field, value)),
    validateField: (field) => dispatch(postEventAccountFieldValidate(field)),
    checkAccountExists: (args) => dispatch(checkAccountExists(args)),
    setRole: (role) => dispatch(setRole(role)),
    validateProfileField: (field) => dispatch(postEventProfileFieldValidate(field)),
    registrationPostEvent: (history, location) =>
      dispatch(registrationPostEvent(history, location)),
    changeProfileField: (name, value) => dispatch(changeProfileField({ name, value })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
