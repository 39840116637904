import { newEventLocation } from 'redux/ducks/helpers';
import { findIndex } from 'lodash';
import * as actions from './actions';
import { V2_CLEAR_FORM, V2_RELOAD_FORM_SUCCESS } from '../actions';
import { Contact, Equipment } from 'redux/ducks/CreateEvent/EAP';
import * as navigationActions from '../Navigation';
import * as postActions from '../actions';

// Reducer Functions
// ========================================================

const formMappingReducer = (updateFn) => {
  return (state, payload) => {
    return {
      ...state,
      forms: state.forms.map((form, idx) => {
        if (idx === state.currentForm) {
          return updateFn(form, payload);
        } else {
          return form;
        }
      }),
    };
  };
};

const fieldChangeReducer = formMappingReducer((form, { name, value }) => ({
  ...form,
  [name]: value,
}));

const eapFormInitSuccessReducer = (state, payload) => {
  const { emergencyActionPlans: forms, locations } = payload;

  const createdForms = forms.map((form) => form.eventLocation.id);
  const locationsThatNeedEAP = locations.filter((location) => !createdForms.includes(location.id));

  forms.push(...locationsThatNeedEAP.map(newEapForm));

  const currentForm = !!payload.eventLocationId
    ? findIndex(forms, (form) => form.eventLocation.id === payload.eventLocationId)
    : INITIAL_FORM;

  return {
    ...state,
    forms,
    currentForm,
    loading: false,
  };
};

const addHospitalReducer = formMappingReducer((form, { hospital }) => ({
  ...form,
  hospital,
}));

const addFireDepartmentReducer = formMappingReducer((form, { fireDepartment }) => ({
  ...form,
  fireDepartment,
}));

const addPoliceStationReducer = formMappingReducer((form, { policeStation }) => ({
  ...form,
  policeStation,
}));

const updateMapReducer = formMappingReducer((form, { map }: { map: string }) => ({
  ...form,
  venueMapUrl: map,
}));

const addEquipmentReducer = formMappingReducer((form, { name = '' }) => {
  return {
    ...form,
    emergencyEquipment: name
      ? [new Equipment(name), ...form.emergencyEquipment]
      : [...form.emergencyEquipment, new Equipment(name)],
  };
});

const removeEquipmentReducer = formMappingReducer((form, { index }) => {
  return {
    ...form,
    emergencyEquipment: form.emergencyEquipment.reduce(
      (acc, eq, idx) =>
        idx !== index ? [...acc, eq] : !eq.id ? acc : [...acc, { ...eq, _destroy: true }],
      []
    ),
  };
});

const updateEquipmentReducer = formMappingReducer((form, { index, equipment }) => {
  return {
    ...form,
    emergencyEquipment: form.emergencyEquipment.map((eq, idx) =>
      idx === index ? { ...eq, ...equipment } : eq
    ),
  };
});

const clearEquipmentReducer = formMappingReducer((form) => {
  return {
    ...form,
    emergencyEquipment: [],
  };
});

const addContactReducer = formMappingReducer((form) => {
  return {
    ...form,
    emergencyContacts: [...form.emergencyContacts, { name: '', role: '', phoneNumber: '' }],
  };
});

const removeContactReducer = formMappingReducer((form, { index }: { index: number }) => {
  return {
    ...form,
    emergencyContacts: form.emergencyContacts.map((contact, idx) =>
      idx === index ? { ...contact, _destroy: true } : contact
    ),
  };
});

const updateContactReducer = formMappingReducer(
  (form, { index, contact }: { index: number, contact: any }) => {
    return {
      ...form,
      emergencyContacts: form.emergencyContacts.map((c, idx) => (idx === index ? contact : c)),
    };
  }
);

const clearContactsReducer = formMappingReducer((form) => {
  return {
    ...form,
    emergencyContacts: [],
  };
});

const createSuccessReducer = (state, payload) => {
  return {
    ...state,
    forms: state.forms.map((form) =>
      form.eventLocation?.id === payload.eventLocation?.id ? payload : form
    ),
  };
};

const reloadFormReducer = (state, payload) => {
  return payload?.eap ? { ...payload.eap } : state;
};

const initFormsReducer = (state, payload) => {
  const { locations } = payload;
  const existingForms = state.forms;

  const newForms = locations.map((location) => {
    const existingForm = existingForms.find((form) => form.eventLocation.id === location.id);
    return existingForm || newEapForm(location);
  });

  return {
    ...state,
    forms: newForms,
  };
};

// Reducer
// ========================================================

export const INITIAL_FORM = 0;

export const newEapForm = (eventLocation = newEventLocation()) => {
  return {
    eventLocation,
    hospital: newEventLocation(),
    policeStation: newEventLocation(),
    fireDepartment: newEventLocation(),
    emergencyContacts: [new Contact()],
    emergencyEquipment: [],
    venueMapUrl: null,
    venueMapFileName: '',
    generateOrUpload: '',
    uploadedPlan: null,
    uploadedPlanPreview: null,
    hasNavigatedForward: false,
  };
};

export const initialState = () => {
  return {
    forms: [],
    currentForm: INITIAL_FORM,
  };
};

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case V2_CLEAR_FORM:
      return initialState();
    case actions.FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);
    case actions.EAP_FORM_INIT:
      return { ...state, loading: true };
    case actions.EAP_FORM_INIT_SUCCESS:
      return eapFormInitSuccessReducer(state, action.payload);
    case actions.FETCH_NEAREST_HOSPITAL_SUCCESS:
      return addHospitalReducer(state, action.payload);
    case actions.FETCH_NEAREST_FIRE_DEPARTMENT_SUCCESS:
      return addFireDepartmentReducer(state, action.payload);
    case actions.FETCH_NEAREST_POLICE_STATION_SUCCESS:
      return addPoliceStationReducer(state, action.payload);
    case actions.MAP_UPDATED:
      return updateMapReducer(state, action.payload);
    case actions.CREATE_EAP_SUCCESS:
    case actions.UPDATE_EAP_SUCCESS:
      return createSuccessReducer(state, action.payload);

    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return initFormsReducer(state, action.payload);
    case actions.ADD_CONTACT:
      return addContactReducer(state, action.payload);
    case actions.REMOVE_CONTACT:
      return removeContactReducer(state, action.payload);
    case actions.CONTACT_UPDATED:
      return updateContactReducer(state, action.payload);
    case actions.CLEAR_CONTACTS:
      return clearContactsReducer(state, action.payload);

    case actions.ADD_EQUIPMENT:
      return addEquipmentReducer(state, action.payload);
    case actions.REMOVE_EQUIPMENT:
      return removeEquipmentReducer(state, action.payload);
    case actions.EQUIPMENT_UPDATED:
      return updateEquipmentReducer(state, action.payload);
    case actions.CLEAR_EQUIPMENT:
      return clearEquipmentReducer(state, action.payload);
    case V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);

    case navigationActions.SET_LOCATION_IDX:
      return {
        ...state,
        currentForm: action.payload.locationIdx,
      };
    default:
      return state;
  }
}
