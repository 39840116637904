import React, { Component } from 'react';
import HorizontalRule from 'components/HorizontalRule';
import UploadDoc from '../components/UploadDoc';
import ActionBar from '../../ActionBar';
import { addEquipment, fieldChange, initEAPForm } from 'redux/ducks/Post/EAP/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { saveLocalForm } from 'redux/ducks/Post/actions';
import { trackMixpanelPageView } from 'helpers/post';

class EmergencyActionPlan extends Component {
  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/eap/eap-type', this.props.user);

    // const { shiftLocation, details } = this.props;
    const callback = () => this.props.saveLocalForm();

    // if (details.id) {
    //   this.props.initEAPForm(details.id, shiftLocation.id);
    // }
    callback();
  }

  // Bug fix for the new users
  // componentDidUpdate(prevProps) {
  //   const { shiftLocation, details } = this.props;
  //   if (!prevProps.details.id && details.id) {
  //     this.props.initEAPForm(details.id, shiftLocation.id);
  //   }
  // }

  handleUploadEAPDoc = () => this.props.onFieldChange('generateOrUpload', 'upload');
  autoGenerateEAPDoc = () => this.props.onFieldChange('generateOrUpload', 'generate');

  pageIsValid = () => {
    if (isEmpty(this.props.form) || !this.props.authenticated) return false;

    const { generateOrUpload, uploadedPlanPreview } = this.props.form;
    return generateOrUpload === 'generate' || uploadedPlanPreview;
  };

  onSaveAndContinue = () => {
    if (!this.pageIsValid()) return;

    const { equipments } = this.props.shiftLocation.supplies;
    if (!this.props.form.id) {
      equipments.forEach((eq) => {
        const subEq = eq?.split(',').map((e) => e.trim());
        subEq.forEach((eq) => this.props.addEquipment(eq));
      });
    }

    this.props.history.push(`${this.props.baseUrl}/eap/review`);
  };

  render() {
    const { form } = this.props;
    if (isEmpty(form)) return null;

    const { uploadedPlan, generateOrUpload, uploadedPlanPreview } = form;
    return (
      <div className="emergencyActionPlan">
        <div id="attribution"></div>
        <main>
          <header>
            <div className="screen-title">Emergency Action Plan</div>
          </header>
          <div className="emergencyActionPlanContainer nimbus-regular-font">
            <p className="title gotham-bold-font">EMERGENCY ACTION PLAN / EAP</p>
            <p>
              An EAP is a document that helps prepare for a catastrophic injury or emergency
              situations and includes the locations and contact information for nearest police
              department, fire department, hospital and emergency services.
            </p>
            <span className="bold">
              The Go4 platform can auto-generate an EAP for your job or you can upload your own.
            </span>

            <HorizontalRule dashed />

            <div className="eap-button-section">
              <button
                className={classNames('eap-auto-generate-button gotham-bold-font', {
                  selected: generateOrUpload === 'generate',
                })}
                onClick={this.autoGenerateEAPDoc}
              >
                Auto-Generate EAP
              </button>
              <div className="eap-upload-section">
                <button
                  className={classNames('eap-upload-button gotham-bold-font', {
                    selected: generateOrUpload === 'upload',
                  })}
                  onClick={this.handleUploadEAPDoc}
                >
                  Upload my own EAP
                </button>
                {generateOrUpload === 'upload' && (
                  <UploadDoc
                    onFieldChange={this.props.onFieldChange}
                    uploadedPlan={uploadedPlan}
                    uploadedPlanPreview={uploadedPlanPreview}
                  />
                )}
              </div>
            </div>
          </div>
          <ActionBar
            editMode={this.props.editMode}
            backTo={`${this.props.baseUrl}/details/credentials`}
            currentStep="eap/eap-type"
            onSaveAndContinue={this.onSaveAndContinue}
            nextDisabled={() => !this.pageIsValid()}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentForm } = state.post.eap;
  const form = get(state, `post.eap.forms[${currentForm}]`, {});
  const { locationIdx } = state.post.navigation;
  const shiftLocation = state.post.locations[locationIdx];

  return {
    form,
    shiftLocation,
    ...state.post,
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFieldChange: (name, value) => dispatch(fieldChange({ name, value })),
  addEquipment: (name) => dispatch(addEquipment({ name })),
  initEAPForm: (eventId, eventLocationId) => dispatch(initEAPForm({ eventId, eventLocationId })),
  saveLocalForm: () => dispatch(saveLocalForm()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmergencyActionPlan));
