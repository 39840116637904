export const stateRates = [
  { state: 'AK', rate: '$48' },
  { state: 'AL', rate: '$40' },
  { state: 'AR', rate: '$38' },
  { state: 'AZ', rate: '$43' },
  { state: 'CA', rate: '$54' },
  { state: 'CO', rate: '$40' },
  { state: 'CT', rate: '$54' },
  { state: 'DC', rate: '$56' },
  { state: 'DE', rate: '$47' },
  { state: 'FL', rate: '$42' },
  { state: 'GA', rate: '$46' },
  { state: 'HI', rate: '$48' },
  { state: 'IA', rate: '$45' },
  { state: 'ID', rate: '$48' },
  { state: 'IL', rate: '$48' },
  { state: 'IN', rate: '$46' },
  { state: 'KS', rate: '$42' },
  { state: 'KY', rate: '$43' },
  { state: 'LA', rate: '$37' },
  { state: 'MA', rate: '$56' },
  { state: 'MD', rate: '$47' },
  { state: 'ME', rate: '$50' },
  { state: 'MI', rate: '$44' },
  { state: 'MN', rate: '$49' },
  { state: 'MO', rate: '$44' },
  { state: 'MS', rate: '$35' },
  { state: 'MT', rate: '$48' },
  { state: 'NC', rate: '$41' },
  { state: 'ND', rate: '$48' },
  { state: 'NE', rate: '$47' },
  { state: 'NH', rate: '$49' },
  { state: 'NJ', rate: '$47' },
  { state: 'NM', rate: '$48' },
  { state: 'NV', rate: '$40' },
  { state: 'NY', rate: '$55' },
  { state: 'OH', rate: '$46' },
  { state: 'OK', rate: '$37' },
  { state: 'OR', rate: '$46' },
  { state: 'PA', rate: '$48' },
  { state: 'RI', rate: '$55' },
  { state: 'SC', rate: '$47' },
  { state: 'SD', rate: '$50' },
  { state: 'TN', rate: '$42' },
  { state: 'TX', rate: '$40' },
  { state: 'UT', rate: '$42' },
  { state: 'VA', rate: '$51' },
  { state: 'VT', rate: '$50' },
  { state: 'WA', rate: '$46' },
  { state: 'WI', rate: '$48' },
  { state: 'WV', rate: '$47' },
  { state: 'WY', rate: '$48' },
];
