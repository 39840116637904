import React, { Component } from 'react';
import moment from 'moment-timezone';
import { get, sumBy } from 'lodash';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { deleteEvent } from 'redux/ducks/Event';
import { connect } from 'react-redux';
import { duplicatePost, clearV2Form, initForm } from 'redux/ducks/Post/actions';
import { getEventLocations, getFirstStartDate, getTimezone } from 'helpers/post';
import {
  shiftStatistics,
  activeShifts,
  getAlerts,
  SHOW_TOOLTIP_COLUMNS,
  createOverflowChecker,
} from 'helpers/dashboard';
import { UncontrolledTooltip } from 'reactstrap';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';

class UpcomingListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
      isDeleteModalOpen: false,
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.upcoming.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'upcoming',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.upcoming.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  toggleDeleteModal = () => this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });

  startDate = () => {
    const { item } = this.props;
    const shifts = Object.values(item.shifts.byId);

    if (item && shifts.length > 0) {
      const futureShifts = activeShifts(shifts);
      return getFirstStartDate(futureShifts).format('MM/DD/YY');
    }
  };

  boostedShift = (shift) => {
    return shift.boostedAt && moment(shift.boostedAt).isAfter(moment().subtract(48, 'hours'));
  };

  shiftPendingConfirmation = (shift) => {
    const { item } = this.props;
    const { capacityFilled, pendingConfirmation } = shiftStatistics(
      Object.values(get(shift, 'jobs.byId', {}))
    );
    if (moment(item.createdAt).isAfter(moment().subtract(48, 'hours'))) {
      return !!pendingConfirmation;
    } else {
      return pendingConfirmation + capacityFilled >= shift.shiftCapacity;
    }
  };

  shiftsFilled = (shifts) =>
    sumBy(shifts, (shift) => shiftStatistics(Object.values(shift.jobs.byId)).capacityFilled);

  eventCapacity = (shifts) => sumBy(shifts, 'shiftCapacity');

  shiftRequests = (shifts) =>
    sumBy(shifts, (shift) => shiftStatistics(Object.values(shift.jobs.byId)).shiftRequests);

  eventEndDate = () => {
    const { item } = this.props;
    const shifts = Object.values(item.shifts.byId);

    if (shifts.length === 0 || shifts.every((shift) => !shift.isMultiDay)) return '';

    const [lastShift] = shifts.sort((a, b) =>
      moment(a.endTime).isBefore(moment(b.endTime)) ? 1 : -1
    );

    const tz = getTimezone(lastShift.eventLocation);

    if (
      moment(item.nextStartTime).format('MM/DD/YY') ===
      moment.tz(lastShift.endTime, tz).format('MM/DD/YY')
    )
      return '';

    return ` - ${moment.tz(lastShift.endTime, tz).format('MM/DD/YY')}`;
  };

  handleAlert = () => {
    const { item } = this.props;
    const shifts = activeShifts(Object.values(item.shifts.byId));
    const shiftsFilled = this.shiftsFilled(shifts);
    const eventCapacity = this.eventCapacity(shifts);
    const shiftRequests = this.shiftRequests(shifts);

    if (shifts.length === 0) return 'ended';

    if (shiftsFilled === eventCapacity) return 'filled';

    if (shiftRequests > 0) return 'review';

    if (shifts.every(this.shiftPendingConfirmation)) return 'confirmation';

    if (moment(item.createdAt).isAfter(moment().subtract(48, 'hours'))) return 'new';

    if (shifts.every(this.boostedShift)) return 'boosted';

    return 'boost';
  };

  onEditEvent = () => {
    const { item } = this.props;
    this.props.initialize({
      ...item,
      shifts: Object.values(get(item, 'shifts.byId', {})),
    });
    this.props.history.push(`/v2/events/${item.id}/profile`);
  };

  onEventTitle = () => {
    const { item } = this.props;

    const locations = getEventLocations(item);
    if (locations.length === 1) {
      this.props.history.push(`/v2/upcoming-event/${item.id}/locations/${locations[0]?.id}`);
      return;
    }

    this.props.history.push(`/v2/upcoming-event/${item.id}/locations`);
  };

  onDeleteEvent = () => {
    this.props.onDelete(this.props.item);
  };

  onDuplicate = () => {
    const { item } = this.props;
    this.props.clearForm();
    this.props.duplicatePost(item);
    this.props.history.push(`/v2/create-event/profile`);
  };

  render() {
    const { item, columns } = this.props;
    const eventAlert = this.handleAlert();
    const { shiftsFilled, eventCapacity, shiftRequests } = item.statistics || {};

    return (
      <div className="upcoming-list-item">
        <div
          className={`${columns['startDate'].size}`}
          id={`startDate-${item.id}`}
          ref={this.columnRefs['startDate']}
        >
          {moment(item.nextStartTime).format('MM/DD/YY')}
          {this.eventEndDate()}
          {this.state.isOverflowing.startDate && (
            <UncontrolledTooltip target={`startDate-${item.id}`}>
              {moment(item.nextStartTime).format('MM/DD/YY')}
              {this.eventEndDate()}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['jobName'].size} job-name`}
          onClick={this.onEventTitle}
          id={`jobName-${item.id}`}
          ref={this.columnRefs['jobName']}
        >
          {item.title}
          {this.state.isOverflowing.jobName && (
            <UncontrolledTooltip target={`jobName-${item.id}`}>{item.title}</UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['filled'].size}`}
          id={`filled-${item.id}`}
          ref={this.columnRefs['filled']}
        >
          {shiftsFilled}/{eventCapacity}
          {this.state.isOverflowing.filled && (
            <UncontrolledTooltip target={`filled-${item.id}`}>
              {shiftsFilled}/{eventCapacity}
            </UncontrolledTooltip>
          )}
        </div>
        <div className={`${columns['alert'].size} ${eventAlert}`}>
          {eventAlert === 'review'
            ? `${shiftRequests} APPLICATION${shiftRequests > 1 ? 'S' : ''}`
            : get(
                getAlerts().find((alert) => alert.key === eventAlert),
                'name'
              )}
        </div>
        <div
          className={classNames(`${columns['actions'].size} event-actions`)}
          style={{
            paddingRight: '10px',
          }}
        >
          <button
            className={classNames('plain-button edit-button')}
            onClick={() => this.onEditEvent(item)}
          >
            EDIT
          </button>
          <button className={classNames('plain-button delete-button')} onClick={this.onDuplicate}>
            DUPLICATE
          </button>
          <button
            className={classNames('plain-button delete-button')}
            onClick={() => this.toggleDeleteModal()}
          >
            CANCEL
          </button>
        </div>
        <DeleteModal
          key={item.id}
          isOpen={!!this.state.isDeleteModalOpen}
          title="Cancel Job"
          body="Canceling this job will remove all shifts and athletic trainers who applied or confirmed to work."
          cancelText="Cancel"
          toggle={this.toggleDeleteModal}
          onDelete={this.props.onDelete}
          item={item}
          isEvent={true}
          user={this.props.user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (event) => dispatch(deleteEvent(event)),
  duplicatePost: (event) => dispatch(duplicatePost(event)),
  clearForm: () => dispatch(clearV2Form()),
  initialize: (originalEvent) => dispatch(initForm(originalEvent)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpcomingListItem));
